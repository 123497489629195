var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sai-resourceTree",class:_vm.isBigCategory(_vm.currentValue) && _vm.isSelected(_vm.currentValue) && 'addBottom'},[(!_vm.isRoot && _vm.currentValue && _vm.currentValue.talkScriptType === 'node')?_c('div',{staticClass:"content",class:[
      _vm.isBigCategory(_vm.currentValue) ? 'withItems' : 'withoutItems',
      _vm.isActive(_vm.currentValue) && 'active',
      _vm.isSelected(_vm.currentValue) && 'selected',
    ],on:{"click":function($event){$event.preventDefault();return _vm.toggleItems(_vm.currentValue)}}},[_c('span',{staticClass:"title"},[_vm._v("\n      "+_vm._s(_vm.currentValue.text)+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.isRoot || _vm.isActive(_vm.currentValue))?_c('div',{ref:"items",class:[
      'items',
      _vm.isActive(_vm.currentValue) && 'active',
      _vm.isSelected(_vm.currentValue) && 'selected',
    ]},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:['item', `talkScriptType-${item.talkScriptType}`]},[_c('ResourceTree',{attrs:{"currentValue":item,"condition":_vm.condition,"isRoot":false,"showResult":_vm.showResult,"selectedCategories":_vm.selectedCategories},on:{"setTagSearchShow":_vm.setTagSearchShow}})],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
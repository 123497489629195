var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scrollY FaqClientTab"},[_c('div',{staticClass:"search"},[_c('div',{staticClass:"container partial"},[_c('h1',{staticClass:"search__title"},[_vm._v("検索して探す")]),_vm._v(" "),_c('TaggedInput',{attrs:{"tags":_vm.selectedTags.values(),"searchText":_vm.searchText,"tagSearchStore":_vm.tagSearchStore},on:{"onTagRemoved":_vm.removeSelectedTag,"onSearchTextChanged":_vm.setSearchText}}),_vm._v(" "),_c('div',{staticStyle:{"position":"relative","z-index":"1800","background-color":"white"}},[_c('TagList',{attrs:{"tags":_vm.candidateTags},on:{"onSelectTag":_vm.addSelectedTag}})],1)],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ready),expression:"ready"}]},[_c('div',{staticClass:"container partial"},[(_vm.filteredItems)?_c('div',[_vm._v("\n        "+_vm._s(_vm.filteredItems.length)+"の質問が見つかりました\n      ")]):_vm._e(),_vm._v(" "),_c('ListContent',{staticClass:"listWrapper",attrs:{"list":_vm.filteredItems},on:{"selected":_vm.selectItem}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.sections.scenario.length || _vm.sections.result.length),expression:"sections.scenario.length || sections.result.length"}],staticClass:"scenario",class:{ hasScenario: _vm.sections.scenario.length }},[_c('div',{staticClass:"scenario__inner"},[_c('a',{attrs:{"id":"scenarioArea"}}),_vm._v(" "),_c('section',{class:{
            scenario__section: true,
            active: _vm.sections.talkScript.end === _vm.navigationStore.state.index,
            hideSection: true,
            section: true,
          }},[_c('div',{staticClass:"container partial"},[(_vm.navigationStore.state.routes[_vm.sections.talkScript.end])?_c('h2',{staticClass:"sectionTitle--scenario"},[_c('span',{staticClass:"sectionTitle__inner--scenario"},[_c('i',{staticClass:"sectionTitle--scenario__icon"}),_vm._v("\n                "+_vm._s(_vm.navigationStore.state.routes[_vm.sections.talkScript.end] &&
                    _vm.navigationStore.state.routes[_vm.sections.talkScript.end].text)+"\n              ")])]):_vm._e(),_vm._v(" "),(_vm.sections.scenario.length > 0)?_c('VerticalNavigation',{attrs:{"navigationStore":_vm.navigationStore,"range":_vm.sections.scenario}}):_vm._e()],1)]),_vm._v(" "),(_vm.sections.result.length)?_c('section',{class:{ active: false, answer: true }},[_c('div',{staticClass:"answer__inner"},[_c('div',{staticClass:"step"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"resultContent"},[(_vm.sections.result.length > 0)?_c('VerticalNavigation',{attrs:{"navigationStore":_vm.navigationStore,"range":_vm.sections.result}}):_vm._e()],1)])])]):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"container partial",staticStyle:{"margin-top":"40px"}},[_c('h1',{staticClass:"sectionTitle"},[_vm._v("よくある質問")]),_vm._v(" "),_c('Frequent',{attrs:{"navigationStore":_vm.navigationStore}})],1),_vm._v(" "),_c('div',{staticClass:"container partial"},[_c('h1',{staticClass:"sectionTitle"},[_vm._v("最近見たFAQ")]),_vm._v(" "),_c('History',{attrs:{"navigationStore":_vm.navigationStore,"historyStore":_vm.historyStore}})],1),_vm._v(" "),_c('div',{staticStyle:{"height":"480px"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"step__title step__title--answer"},[_c('i',{staticClass:"step__title--answer__icon"}),_c('span',[_vm._v("回答")])])
}]

export { render, staticRenderFns }